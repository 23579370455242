.EditBillingAddress {
	.EditBillingAddress-form {
		@apply tw-grid tw-grid-cols-12 tw-gap-y-4 tw-gap-x-4 tw-w-full tw-items-baseline;
	}
	.EditBillingAddress-control {
		@apply tw-w-full;
	}
	.EditBillingAddress-zipCodeControl {
		@apply tw-w-full;
	}
}

.expirationWarning {
	@apply tw-bg-gold/30 tw-relative;

	width: 684px;
	padding: 18px 15px 16px 46px;
}

.expirationIcon {
	@apply tw-absolute;

	left: 16px;
	width: 22px;
	height: 20px;
}

@screen md {
	.EditBillingAddress {
		.EditBillingAddress-form {
			width: 339px;
		}
		.EditBillingAddress-zipCodeControl {
			width: 95px;
		}
	}
}
